import React, { useState } from 'react';
import SharedFlatpickr from "@shared/flatpickr"
import Checkbox from '@shared/checkbox/checkbox';

const ReceiveGoodsForm = ({ purchaseOrder, purchaseLines: initialPurchaseLines, backRoute }) => {
  const [purchaseLines, setPurchaseLines]       = useState(initialPurchaseLines);
  const [filter, setFilter]                     = useState('');
  const [hideDelivered, setHideDelivered]       = useState(false);
  const allowSearch                             = purchaseLines.length > 5;

  const handlePrefillQuantity = () => {
    const updatedLines = purchaseLines.map(line => {
      // Prefill quantity only for filtered lines
      if (line.displayReception.toLowerCase().includes(filter.toLowerCase())) {
        return { ...line, quantityReceivedNow: line.quantityComing };
      }
      return line;
    });
    setPurchaseLines(updatedLines);
  };

  const handleQuantityChange = (e, lineId) => {
    const updatedLines = purchaseLines.map(line =>
      line.id === lineId ? { ...line, quantityReceivedNow: e.target.value } : line
    );
    setPurchaseLines(updatedLines);
  };

  const handleFullyDeliveredChange = (checked, lineId) => {
    const updatedLines = purchaseLines.map(line => {
      if (line.id === lineId) {
        return {
          ...line,
          quantityReceivedNow: checked ? line.quantityComing : '',
          fullyDelivered: checked
        };
      }
      return line;
    });
    setPurchaseLines(updatedLines);
  };

  const handleHideDeliveredChange = () => (checked) => {
    setHideDelivered(checked);
  };

  return (
    <div>
      <h3>{I18n.t('purchase_orders.reception.title_modal', { number: purchaseOrder.number })}</h3>

      <div className="d-grid g-t-col-12">
        <div className="d-contents">
          {/* Order date */}
          <div className="g-c-span-4">
            <div className="d-flex flex-start">
              <label>{I18n.t("purchase_orders.reception.order_date")}</label>
              <strong className="w-40 ml-2" style={{'paddingTop': '6px', 'paddingBottom': '5px'}}>
                {purchaseOrder.creation_date}
              </strong>
            </div>
          </div>

          {/* Reception date */}
          <div className="g-c-span-4">
            <div className="d-flex flex-end">
              <label>{I18n.t("purchase_orders.reception.reception_date")}</label>
              <SharedFlatpickr
                className="form-control flatpickr-nobg text-center ml-2 w-40"
                value    = {new Date().toLocaleDateString('fr-FR')}
                options={{
                  dateFormat: "d/m/Y",
                  locale: I18n.locale,
                  mode: 'single'
                }}
                name="reception_date"
                required
              />
            </div>
          </div>

          {/* Search Filter */}
          <div className="g-c-span-4 ml-2 mb-3">
            {allowSearch && (
              <input
                type="text"
                placeholder={I18n.t('purchase_orders.reception.search_placeholder')}
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
                className="form-control"
              />
            )}
          </div>

        </div>
      </div>

      <div style={{ height: '42vh', overflowY: 'scroll' }}>
        <div className="purchase-reception-grid mb-3">
          <div className="purchase-reception-grid__header">
            <div>{I18n.t('purchase_orders.reception.description')}</div>
            <div className="text-right pr-2">{I18n.t('purchase_orders.reception.quantity_ordered')}</div>
            <div className="text-right pr-2">{I18n.t('purchase_orders.reception.quantity_already_received')}</div>
            <div className="text-right pr-2">{I18n.t('purchase_orders.reception.quantity_coming')}</div>
            <div className="text-center">{I18n.t('purchase_orders.reception.quantity_received_now')}</div>
            <div>&nbsp;</div>
          </div>

          {/* Purchase Lines List */}
          {purchaseLines
            .filter(line => {
              // filter those matching the search term
              return line.displayReception.toLowerCase().includes(filter.toLowerCase()) &&
              // and hide fully delivered if checkbox unchecked
              (!hideDelivered || line.quantityComing > 0)
            })
            .map(line => (
              <div key={line.id} className="d-contents">
                <input
                  type="hidden"
                  name={`purchase_order[purchase_lines_attributes][${line.id}][id]`}
                  value={line.id}
                />

                <div>
                  {line.displayReception}
                </div>

                <div className="text-right pr-2">
                  {line.quantityOrdered}
                </div>

                <div className="text-right pr-2">
                  {line.quantityAlreadyReceived}
                </div>

                <div className="text-right pr-2">
                  {line.quantityComing}
                </div>

                <div className="text-right">
                  {!line.hasSerialNumbers && (
                    <input
                      type="number"
                      name={`purchase_order[purchase_lines_attributes][${line.id}][quantity_received_now]`}
                      step="0.01"
                      min="0"
                      value={line.quantityReceivedNow || ''}
                      onChange={(e) => handleQuantityChange(e, line.id)}
                      className="form-control text-right quantity-received-now"
                    />
                  )}
                </div>

                <div className="text-center">
                  <Checkbox
                    id        = {`${line.id}_fullyDelivered`}
                    checked   = {line.fullyDelivered}
                    className = 'pl-2 mb-0'
                    onChange  = {(e) => handleFullyDeliveredChange(e, line.id)}
                  />
                </div>

                {/* Handle Products with Serial Numbers */}
                {line.hasSerialNumbers && (
                  <div className="d-contents">
                    <div></div>
                    <div className="g-c-span-5">
                      {line.serialNumbers.map((receivedSerial, i) => (
                        <input
                          key={i}
                          type="text"
                          value={receivedSerial}
                          className="form-control mb-1"
                          disabled
                        />
                      ))}

                      {[...Array(line.quantityComing)].map((_, i) => (
                        <input
                          key={i}
                          type="text"
                          name={`purchase_order[purchase_lines_attributes][${line.id}][serials_received_now][]`}
                          placeholder={I18n.t('products.serial_number')}
                          className="form-control mb-1"
                        />
                      ))}
                    </div>
                  </div>
                )}
              </div>
            ))}
        </div>
      </div>

      <div className="d-grid g-t-col-12">
        <div className="d-contents">
          {/* Include items already fully delivered */}
          <div className="g-c-span-6">
            <Checkbox
              name      = 'hideDelivered'
              checked   = {hideDelivered}
              id        = {'hideDelivered'}
              onChange  = {handleHideDeliveredChange()}
              label     = {I18n.t("purchase_orders.reception.hide_delivered")}
              className = "form-group"
            />
          </div>

          {/* Prefill Quantity Button */}
          <div className="g-c-span-6 text-right" style={{ alignSelf: 'start' }}>
            <a href="#"
              onClick={(e) => {
                e.preventDefault();
                handlePrefillQuantity();
              }}
            >
              <i className="fas fa-plus-circle mr-1"></i>
              {I18n.t('purchase_orders.reception.prefill_quantity')}
            </a>
          </div>
        </div>
      </div>

    </div>
  );
};

export default ReceiveGoodsForm;
